@import 'src/scss/theme';

.container {
  border-radius: 24px;
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.2), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin: 0 0 2rem;
  width: max-content;
  min-height: 300px;
}

.headerContainer {
  padding: 0.5rem 1.2rem;
  background-color: #f9fafb;
  border-bottom: 1px solid $border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px 24px 0 0;
}

.headerTitle {
  margin: auto 0;
  color: #374151;
  font-weight: 700;
  font-size: 18px;
}

.subHeader {
  font-size: 14px;
  margin: 0.25rem 0 0;
  color: $subtext-color;
}

.detailsContainer {
  padding: 0 1.2rem 0.5rem;
  max-height: 315px;
  overflow: auto;
}

// Media Queries

@media screen and (max-width: 991px) {
  .headerContainer {
    padding: 1rem;
  }
}
