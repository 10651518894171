.descriptionContainer {
  color: white;
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  font-weight: 500;
  box-sizing: border-box;
  overflow: auto;
}

.descriptionButton {
  position: absolute !important;
  right: 0.5rem;
  bottom: 0.5rem;
}
