@import 'breakpoints';

$primary-color: #ffb33f;
$primary-gradient-color: linear-gradient(102.9deg, #fcd34d 16.12%, #ffb33f 80.67%);
$header-color: #1f2937;
$subtext-color: #6b7280;
$label-color: #4b5563;
$border-color: #e5e7eb;

$success-color: #25a743;
$danger-color: #dc3545;
$warning-color: #ffc00e;

$pending-status-color: #fef3c7;
$active-status-color: #d1fae5;
$inactive-status-color: #fee2e2;
$overdue-status-color: #fef0e2;
