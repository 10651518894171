.textLink {
  color: darken($primary-color, 20%) !important;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.exteriorLink {
  color: rgb(0, 0, 238);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
