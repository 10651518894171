@import 'src/scss/theme';

.labelAndValue {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label {
  font-weight: bold !important;
  padding-right: 1rem;
  text-align: right;
}

.value {
  text-align: left;
}
